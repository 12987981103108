// -----------------------------------------------------------------------------
// UI COMPONENT : Button' style
// -----------------------------------------------------------------------------
.like-button {
  border: 1px solid #000;
  border-radius: 13%;
  padding: 5px;
  background-color: #bdd7ed;
  cursor: pointer;
}

.like-button:hover {
  border-width: 2px;
  background-color: #fff;
  text-shadow: 2px 2px 0 #207CA3D8, 2px -2px 0 #207CA3D8, -2px 2px 0 #207CA3D8, -2px -2px 0 #207CA3D8, 2px 0px 0 #207CA3D8, 0px 2px 0 #207CA3D8, -2px 0px 0 #207CA3D8, 0px -2px 0 #207CA3D8, 2px 2px 2px rgba(102, 48, 206, 0);
  color: #fff;
}