.search_bar_error {
  position: absolute;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  top: 0;
  padding: 12px;
  width: fit-content;
  background-color: #fff;
  color: #db2a2a;
  font-weight: bold;
  border: 3px solid red;
  display: none;

  &.is-open {
    display: block;
    animation: open-search-bar-error 0.5s ease 0s 1 normal forwards;
  }

  &.do-close {
    animation: close-search-bar-error 0.5s ease 0s 1 normal forwards;
  }
}

@keyframes close-search-bar-error {
  0% {
    transform: translateX(-50%) translateY(0px);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-50px);
  }
}

@keyframes open-search-bar-error {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }
}