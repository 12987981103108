// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: 'proxima_regular' !default;

/// App title font family
/// @type List
$app-title-bold-font-stack: "Open Sans", sans-serif;

/// Text bold font family
/// @type List
$text-bold-font-stack: 'optima' !default;


/// Copy text color
/// @type Color
$text-color: lighten(rgb(19, 18, 16), 0%) !default;

/// Main brand color
/// @type Color
$brand-color: #000000 !default;

/// Background color
/// @type Color
$background-color: rgba(32, 124, 163, 0.85);

/// Container's maximum width
/// @type Length
$max-width: 100% !default;


/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
        'extra-small': (max-width 600px),
        'small': (min-width 600px),
        'medium': (min-width: 800px),
        'large': (min-width: 1000px),
        'extra-large': (min-width: 1200px),
        'phone-tablet': (max-width: 1000px)
);


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'https://cdn.example.com/assets/';
$base-url: '../' !default;

$search-icon: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>');