.search_bar .dropdown {
  /**
  TESTS
   */
  display: none;

  position: absolute;
  top: 77px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 97%;
  left: 50%;
  height: 90vh;
  transform: translateX(-50%);
  overflow: auto;

  &.is-open {
    display: block;
  }
}

.search_bar .dropdown__content {
  //padding: 10px 0 0;
  background-color: #fff;
}

.search_bar .dropdown__content button {
  display: none;
  background: none;
  letter-spacing: 3px;
  outline: none;
  cursor: pointer;
  padding: 10px 0;
  border: none;
  width: 100%;
  font-size: 1.2em;
  text-transform: full-size-kana;
  border: 1px solid #000000;
  border-width: 0 0 1px;

  &.in-flow {
    display: block;
  }
}

.search_bar .dropdown__content button:hover,
.search_bar .dropdown__content button.current {
  color: #FFFFFF;
  text-shadow: 2px 2px 0 #207CA3D8, 2px -2px 0 #207CA3D8, -2px 2px 0 #207CA3D8, -2px -2px 0 #207CA3D8, 2px 0px 0 #207CA3D8, 0px 2px 0 #207CA3D8, -2px 0px 0 #207CA3D8, 0px -2px 0 #207CA3D8, 2px 2px 2px rgba(102, 48, 206, 0);
}