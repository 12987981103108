.search_bar__controls {
  position: absolute;
  top: 4px;
  right: 30px;
  height: 100%;
  display: none;

  &.multi_nodes_search {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }

  &.uniq_node_search {
    grid-template-columns: 1fr;
  }

  & > div {
    width: 30px;
    cursor: pointer;
  }

  &.is-open {
    display: grid;
    animation: open-search-bar-controls 2s ease 0s 1 normal forwards;
  }
}

@keyframes open-search-bar-controls {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.search_bar__contenteditable {
  position: absolute;
  //width: 425px;
  width: auto;
  font-size: 0.8em;
  top: 0;
  left: 0;
/*  left: 50%;
  transform: translateX(-50%);*/
  padding: 10px;
  color: #000000;
  background: #FFFFFF;
  text-align: center;
  border: 1px solid #000000;
  border-width: 0 1px 1px 0;
  box-shadow: 0px 0px 14px 5px rgba(124, 138, 138, 0.75);
  -webkit-box-shadow: 0px 0px 14px 5px rgba(124, 138, 138, 0.75);
  -moz-box-shadow: 0px 0px 14px 5px rgba(124, 138, 138, 0.75);
}

.search_bar__contenteditable__placeholder::before {
  content: $search-icon;
  width: 20px;
  display: inline-block;
  margin: 2px 5px 0 0;
  cursor: pointer;
}

.search_bar__contenteditable.is-open {
  animation: focusin-search-bar 1s ease 0s 1 normal forwards;
}

@keyframes focusin-search-bar {
  0% {
    width: 425px;
  }
  100% {
    //width: 97%;
    width: 99%;
    font-size: 1.5em;
    box-shadow: 0px 0px 14px 32px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 14px 32px rgba(0, 0, 0, 0.75);
    border: none;
    -moz-box-shadow: 0px 0px 14px 32px rgba(0, 0, 0, 0.75);
  }
}

.search_bar__contenteditable:focus::after {
  content: '';
}

.search_bar__contenteditable__placeholder {
  font-style: italic;
}