.hierarchy_tree_wrapper {
  background-color: #ffffff;
  cursor: all-scroll;
  overflow: hidden;
  height: 96vh;
  margin: 10px 0 0;
}

.hierarchy_tree_wrapper svg {
  width: 100%;
  height: 100%;
}

.hierarchy_tree_wrapper svg .node circle {
  stroke: #000000;
  stroke-width: 1px;
}

.hierarchy_tree_wrapper svg .node circle.node-found {
  stroke: #FF0000FF;
  stroke-width: 5px;
}

.hierarchy_tree_wrapper .node-name {
  font-size: 1em;
  font-weight: bold;
}

.hierarchy_tree_wrapper .node-name.as-link {
  cursor: pointer;
}

.hierarchy_tree_wrapper .node-name.node-found {
  font-size: 1.5em;
  fill: #FF0000FF;
  stroke: #000000;
}

.hierarchy_tree_wrapper .node-name.as-link:hover {
  text-decoration: underline;
}

.hierarchy_tree_wrapper path.link {
  fill: none;
  stroke: #d6c1c1;
  stroke-width: 1px;
}

.hierarchy_tree_wrapper path.link.node-found {
  stroke: #FF7F50FF;
  stroke-width: 2px;
}