.search_bar__selected_nodes {
  max-width: 25%;
  background-color: rgba(111, 144, 164, 0.9);
  left: 0;
  position: fixed;
  top: 60px;
  z-index: 1;
  padding: 0 5px 0 29px;
  display: none;
  border-radius: 0 0 5%;
  max-height: 90vh;
  overflow: auto;
  overflow-wrap: break-word;

  &.is-open {
    display: block;
    animation: open-section-selected-nodes-to-search 1s ease 0s 1 normal forwards;
  }

  h1 {
    color: #ffffff;

    &.show {
      display: block;
    }
  }

  .search_bar__selected_nodes__list.show {
    display: block;
  }

  .selected_node_to_search:last-child {
    border: none;
  }

  .selected_node_to_search {
    border-style: solid;
    border-color: #000000;
    border-width: 0 0 1px;
    position: relative;
    margin: 10px 0;
    color: #FFFFFF;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 0;
  }
}

@keyframes open-section-selected-nodes-to-search {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateX(-250px);
  }

  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateX(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateX(-68px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateX(-28px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateX(-8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateX(0);
  }
}

.search_bar__selected_nodes.is-open.full-width {
  width: 98.7vw;
  max-width: 98.7vw;
}

.search_bar__selected_nodes__open_section_button {
  width: 30px;
  display: none;
  margin: 1px 0 0;
  cursor: pointer;

  &.hide {
    display: none;
  }
}

.search_bar__selected_nodes__close_section_button {
  display: none;
  right: 6px;
  top: 3px;
  position: absolute;
  width: 30px;
  cursor: pointer;

  &.show {
    display: block;
  }
}

.selected_node_to_search__remove {
  width: 18px;
  display: inline-block;
  vertical-align: middle;
}

@include respond-to("phone-tablet") {
  .search_bar__selected_nodes {
    border-radius: 0;
  }

  .search_bar__selected_nodes__open_section_button {
    display: block;
  }

  .search_bar__selected_nodes h1,
  .search_bar__selected_nodes__list {
    display: none;
  }
}