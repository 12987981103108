.hierarchy_tree_wrapper .hierarchy_tree_spinner_layer {
  background-color: #E1E814E0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  opacity: 0.8;
  display: none;
  top: 0;
  left: 0;

  &.show {
    display: block;
  }
}

.hierarchy_tree_wrapper .hierarchy_tree_spinner {
  width: 10%;
  animation: rotating 1s linear infinite;
  position: absolute;
  z-index: 100;
  left: 45%;
  top: 0;
  display: none;

  &.show {
    display: block;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}