// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;
  outline: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

body {
  background-color: $background-color;
  margin: 0;
  height: 100vh;
  color: $text-color;
  overflow: hidden;
}

body.no-scrollable {
  overflow: hidden;
}