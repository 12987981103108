/**
 * Basic typography style for copy text
 */

body {
  font: 17px $text-font-stack;
}

h1 {
  font: 2em $app-title-bold-font-stack;
}