// -----------------------------------------------------------------------------
// This file contains all styles related to the main content's view of the site/application.
// -----------------------------------------------------------------------------

.layer {
  background-color: rgba(79, 149, 179, 0.85);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  display: none;
  opacity: 0.85;
  top: 0;
}

.layer--is-enabled {
  display: block;
  animation: layer-is-enabling 0.5s ease 0s 1 normal forwards;
}

.popin-node-information {
  position: fixed;
  overflow: hidden;
  color: #000000;
  z-index: 100;
  background-color: #EEECF4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  bottom: 25%;
  display: none;
  font-family: "Open Sans", sans-serif;
  line-height: 1.7;
  font-weight: normal;
  font-size: 1.2em;
  height: fit-content;
  max-height: 75%;
}

.popin-node-information hr {
  border: 1px solid #000000;
}

.popin-node-information--is-open {
  display: block;
  animation: popin-is-opening 1s ease 0s 1 normal forwards;
}

.popin-node-information__close-button {
  width: 1.5em;
  border-radius: 48%;
  cursor: pointer;
  position: absolute;
  top: 1%;
  left: 1%;
  background-color: #ffffff;
  box-shadow: -7px -9px 1px 15px rgba(255, 255, 255, 1);
  -webkit-box-shadow: -7px -9px 1px 15px rgba(255, 255, 255, 1);
  -moz-box-shadow: -7px 1px -9px 15px rgba(255, 255, 255, 1);
}

.popin-node-information a:link, a:visited {
  color: #000000;
  text-decoration: none;
  border: 2px solid #000000;
  padding: 6px;
  border-radius: 33px;
}

.popin-node-information a:hover {
  background-color: #ffffff;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: $max-width; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  width: 100%; /* 1 */
  margin-top: 10px;
}